function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

function makeResponsive(){
    let content = document.getElementById('main-content');
    let images = content.getElementsByTagName('img');
    for(let i=0; i<images.length; i++){
      images[i].classList.add('img-fluid');
    }

    let iframes = content.getElementsByTagName('iframe');
    for(let i=0; i<iframes.length; i++){
      iframes[i].classList.add('lazyload');
      let wrapper = document.createElement('div');
      wrapper.classList.add('embed-responsive');
      wrapper.classList.add('embed-responsive-16by9');
      wrap(iframes[i], wrapper);
    }
}
export {makeResponsive, wrap};